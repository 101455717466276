import React from "react";
import { cardArr } from "../../utils/constant";

const GCS_BUCKET = "https://storage.googleapis.com/public-media-prod/medidash-files/";

const Welcome: React.FC = () => {
  const handleDownloadAll = () => {

    const allLinks : string[] = [];
    cardArr.forEach((card) => allLinks.push(card.links.expanded, card.links.abbreviated));

    for (let i = 0; i < allLinks.length ; i++){
      var time = i * 400
      setTimeout(function(){
        const cardLink = allLinks[i]
        const link = document.createElement("a");
        link.style.display = 'none';
        link.setAttribute('id', 'download-link' + i)
        link.href = `${GCS_BUCKET}${cardLink}`;
        link.download = cardLink;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        }, time)
    }

  };

  return (
    <div className="container mx-auto mb-12">
      <div
        className="bg-no-repeat bg-right bg-auto px-5 py-10 md:px-16 md:py-py126 rounded-b-3xl"
        style={{
          backgroundImage:
            "url(/images/wave-bg.png), linear-gradient(95.1deg, #227962 3.93%, #078DAB 109.33%)",
        }}
      >
        <h2 className="text-whiteLilac text-2xl md:text-58xl font-normal leading-56 mb-5">
          ASCO 2024 Highlights
        </h2>
        <button
          onClick={handleDownloadAll}
          className="border shadow-smd text-xl rounded-full border-solid border-limedSpruce px-10 mt-3 mb-0 py-3 bg-white"
        >
          Download all
        </button>
      </div>
    </div>
  );
};

export default Welcome;
