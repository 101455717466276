export const LocalStorageKey = {
  SIGNUP_DATA: "signUpData",
};

export const DEFAULT_IMAGE_URL =
  "https://i0.wp.com/app.podkite.com/images/adorable.png";

export const MIXPANEL_CONFIG = {
  debug: false,
  track_pageview: true,
  ignore_dnt: true,
  persistence: "localStorage" as "localStorage",
};

export const MIXPANEL_TOKEN = "33c110ea306d9ed5ab339ce9ae085812";

export const cardArr = [
  {
    id: 1,
    title: "LUNG",
    description:
      "LBA4, LBA5, LBA8007, LBA8500, LBA8503, LBA8505, LBA8509, NRG-LU02",
    links: {
      expanded: 'Lung/Lung%20Expanded.pptx',
      abbreviated: 'Lung/Lung%20Abbreviated.pptx',
    }
  },
  {
    id: 2,
    title: "BREAST",
    description: "LBA500, LBA501, LBA1000, LBA1001, 1003",
    links: {
      expanded: 'Breast/Breast Expanded.pptx',
      abbreviated: 'Breast/Breast Abbreviated.pptx',
    }
  },
  {
    id: 3,
    title: "HEMATOLOGY",
    description: "LBA105, LBA6500, LBA7005",
    links: {
      expanded: 'Hematology/Hematology Expanded.pptx',
      abbreviated: 'Hematology/Hematology Abbreviated.pptx',
    }
  },
  {
    id: 4,
    title: "GASTROINTESTINAL",
    description: "LBA1, LBA3504",
    links: {
      expanded: 'Gastrointestinal/Gastrointestinal Expanded.pptx',
      abbreviated: 'Gastrointestinal/Gastrointestinal Abbreviated.pptx',
    }
  },
  {
    id: 5,
    title: "GENITOURINARY",
    description: "LBA5000",
    links: {
      expanded: 'Genitourinary/Genitourinary Expanded.pptx',
      abbreviated: 'Genitourinary/Genitourinary Abbreviated.pptx',
    }
  },
  {
    id: 6,
    title: "MELANOMA",
    description: "LBA2, LBA9512",
    links: {
      expanded: 'Melanoma/Melanoma Expanded.pptx',
      abbreviated: 'Melanoma/Melanoma Abbreviated.pptx',
    }
  },
  {
    id: 7,
    title: "OTHER",
    description: "3007, LBA3510",
    links: {
      expanded: 'Other/Other Expanded.pptx',
      abbreviated: 'Other/Other Abbreviated.pptx',
    }
  },
];
