import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import SignUp from "./SignUp";
import { useUser } from "../context/UserContext";

const Feedback = () => {
  const { userData } = useUser();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!userData || !userData.firstName) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [userData]);

  return (
    <div className="items-center justify-center p-4">
      {(!userData || !userData.firstName) && (
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => ({})}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="relative w-w527 transform rounded-2xl tellUsWhat p-6 text-left align-middle transition-all">
                    <SignUp setIsOpen={setIsOpen} />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </div>
  );
};

export default Feedback;
