import React, { useState } from "react";
import Logo from "./icon/logo";
import { removeLocalStorage } from "../utils/localStorage";
import { LocalStorageKey } from "../utils/constant";
import { getGravatarUrl } from "../utils/getGravatarUrl";
import { useUser } from "../context/UserContext";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { userData, setUserData } = useUser();

  const gravatarUrl = getGravatarUrl(userData?.email || "");

  //@ts-ignore
  const initials = userData?.firstName?.charAt(0) + userData?.lastName?.charAt(0);
  const [hasGravatar, setHasGravatar] = useState(true)

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    removeLocalStorage(LocalStorageKey.SIGNUP_DATA);
    setUserData(null);
  };

  return (
    <>
      <header className="h-h72 z-10 bg-paleGrey fixed top-0 w-full backdrop-blur-sm">
        <nav
          className="mx-auto flex items-center justify-between p-4 lg:px-8 h-h72"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <button className="-m-1.5 p-1.5">
              <a href="https://www.oneoncology.com/" target="_blank">
                <Logo />
              </a>
            </button>
          </div>

          {userData && userData.firstName && (
            <div className="lg:flex lg:flex-1 lg:justify-end font-roboto font-bold">
              <button
                className="font-roboto font-bold w-10 h-10 relative"
                style={{ backgroundColor: "#227962", borderRadius: '30px' }}
                onClick={handleMenuToggle}
              >
                {hasGravatar ? (<img src={gravatarUrl} className="rounded-full" alt="" onError={() => setHasGravatar(false)}/>) :
                  (<div className="text-white uppercase font-normal">{initials}</div>)}
                {isMenuOpen && (
                  <div className="flex justify-center items-center absolute top-12 right-0">
                    <div className="max-w-sm rounded-md shadow-lg bg-white border border-solid border-softPeach">
                      <div className="relative flex justify-end">
                        <div className="bg-white absolute w-3 h-3 transform rotate-45 -mt-1 right-4 z-m1 border border-solid border-softPeach"></div>
                      </div>
                      <div className="px-6 py-4">
                        <button onClick={handleLogout} className="font-normal">
                          Logout
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </button>
            </div>
          )}
        </nav>
      </header>
    </>
  );
};

export default Header;
