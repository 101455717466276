import React, { useState } from "react";
import Cards from "./Cards";
import { cardArr } from "../../utils/constant";
import mixpanel from "mixpanel-browser";
import { useUser } from "../../context/UserContext";

const GCS_BUCKET = "https://storage.googleapis.com/public-media-prod/medidash-files/";

const DownloadList = () => {
  const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
  const { userData } = useUser();

  const handleSelect = (id: number) => {
    setSelectedFiles((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((fileId) => fileId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDownload = () => {
    selectedFiles.forEach((fileId) => {
      const files = cardArr.filter((card) => selectedFiles.includes(card.id));

      for (let i = 0; i < files.length ; i++){
        var time = i * 300
        setTimeout(function(){
          const card = files[i]
          const link = document.createElement("a");
          link.style.display = 'none';
          link.setAttribute('id', 'download-link' + i)
          link.href = `${GCS_BUCKET}${card?.links.expanded}`;
          link.download = card?.links.expanded;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          }, time)
      }
    });

    const selectedFileNames = selectedFiles
      .map((fileId) => {
        const file = cardArr.find((card) => card.id === fileId);
        return file ? file.title : "";
      })
      .filter(Boolean);

    mixpanel.track("MD_OO_SEVERAL_FILES_DOWNLOAD", {
      userID: userData?.email,
      documentNames: selectedFileNames,
    });
  };

  const handleSingleDownload = (fileName: string) => {
    const link = document.createElement("a");
    link.href = `${GCS_BUCKET}${fileName}`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    mixpanel.track("MD_OO_FILE_DOWNLOAD", {
      userID: userData?.email,
      fileName,
    });
  };

  const handleCancelSelection = () => {
    setSelectedFiles([]);
  };

  return (
    <>
      {selectedFiles.length > 0 && (
        <div className="container mx-auto mb-9">
          <div className="bg-white px-6 py-6 rounded-2xl rounded-tr-none flex items-center justify-between gap-4 flex-col md:flex-row shadow-ssm">
            <p className="text-limedSpruce text-base font-bold">
              Selected Files
            </p>
            <div className="flex items-center justify-center gap-3">
              <button
                onClick={handleDownload}
                className="border shadow-smd rounded-full border-solid border-limedSpruce px-8 py-1.5"
              >
                Download {selectedFiles.length} Files
              </button>
              <button onClick={handleCancelSelection}>
                <img
                  src="/images/close.svg"
                  alt="Cancel Selection"
                  className="w-6 h-6"
                />
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="container mx-auto mb-9">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 gap-y-9">
          {cardArr.map((item) => (
            <Cards
              key={item.id}
              title={item.title}
              description={item.description}
              selected={selectedFiles.includes(item.id)}
              onSelect={() => handleSelect(item.id)}
              handleDownload={() => handleSingleDownload(item.links.expanded)}
              handleDownloadAbbr={() => handleSingleDownload(item.links.abbreviated)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default DownloadList;
