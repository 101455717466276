import React from "react";

const Footer = () => {
  return (
    <div className="bg-white">
      <div className="container mx-auto">
        <div className="px-6 py-6 flex items-center justify-between gap-4 flex-col md:flex-row">
          <div className="flex items-center justify-start gap-4">
            <img
              src="/images/short-logo.svg"
              alt="logo"
              className="w-9 h-auto"
            />
            <h6 className="text-base font-light text-rangoonGreen">
              Improving the lives of{" "}
              <span className="text-eucalyptus">everyone</span>
              <br />
              living with cancer.
            </h6>
          </div>
          <p className="text-limedSpruce text-base font-medium font-darker-grotesque">
            © 2024 OneOncology
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
