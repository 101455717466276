import React, { ReactNode, useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { useUser } from "./context/UserContext";
import { MIXPANEL_CONFIG, MIXPANEL_TOKEN } from "./utils/constant";

interface AnalyticsProps {
  children: ReactNode;
}

const Analytics: React.FC<AnalyticsProps> = ({ children }) => {
  const { userData } = useUser();

  useEffect(() => {
    mixpanel.init(MIXPANEL_TOKEN, MIXPANEL_CONFIG);
  }, []);

  useEffect(() => {
    if (userData == null || userData.email == null) {
      return;
    }
    mixpanel.identify(userData.email || userData.firstName);
    mixpanel.people.set({
      $firstName: userData.firstName,
      $lastName: userData.lastName,
      $email: userData.email,
    });
  }, [userData]);

  return <>{children}</>;
};

export default Analytics;
