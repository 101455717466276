import md5 from "md5";
// import { DEFAULT_IMAGE_URL } from "./constant";

export const getGravatarUrl = (
  email: string,
  size = 200,
) => {
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=404`;
};
