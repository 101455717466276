const DownloadIcon = ({ width, height }: { width: string; height: string }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.99995 14.4362C9.83662 14.4362 9.68349 14.4105 9.54058 14.359C9.39766 14.3084 9.26495 14.2218 9.14245 14.0993L4.73245 9.68934C4.50787 9.46475 4.39558 9.17892 4.39558 8.83184C4.39558 8.48475 4.50787 8.19892 4.73245 7.97434C4.95703 7.74975 5.24777 7.63215 5.60465 7.62154C5.96235 7.61174 6.25349 7.71913 6.47808 7.94371L8.77495 10.2406V1.48184C8.77495 1.13475 8.89255 0.843611 9.12775 0.608411C9.36213 0.374028 9.65287 0.256836 9.99995 0.256836C10.347 0.256836 10.6382 0.374028 10.8734 0.608411C11.1078 0.843611 11.225 1.13475 11.225 1.48184V10.2406L13.5218 7.94371C13.7464 7.71913 14.0376 7.61174 14.3953 7.62154C14.7521 7.63215 15.0429 7.74975 15.2675 7.97434C15.492 8.19892 15.6043 8.48475 15.6043 8.83184C15.6043 9.17892 15.492 9.46475 15.2675 9.68934L10.8575 14.0993C10.735 14.2218 10.6022 14.3084 10.4593 14.359C10.3164 14.4105 10.1633 14.4362 9.99995 14.4362ZM2.64995 19.8568C1.9762 19.8568 1.39963 19.6171 0.920251 19.1378C0.440051 18.6576 0.199951 18.0806 0.199951 17.4068V14.9568C0.199951 14.6098 0.317143 14.3186 0.551526 14.0834C0.786726 13.849 1.07787 13.7318 1.42495 13.7318C1.77203 13.7318 2.06318 13.849 2.29838 14.0834C2.53276 14.3186 2.64995 14.6098 2.64995 14.9568V17.4068H17.35V14.9568C17.35 14.6098 17.4676 14.3186 17.7028 14.0834C17.9371 13.849 18.2279 13.7318 18.575 13.7318C18.922 13.7318 19.2128 13.849 19.4472 14.0834C19.6824 14.3186 19.8 14.6098 19.8 14.9568V17.4068C19.8 18.0806 19.5603 18.6576 19.0809 19.1378C18.6007 19.6171 18.0237 19.8568 17.35 19.8568H2.64995Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default DownloadIcon;
