import React, { useEffect } from "react";
import Feedback from "./Feedback";
import { LocalStorageKey } from "../utils/constant";
import { getLocalStorage } from "../utils/localStorage";
import { useUser } from "../context/UserContext";
import Welcome from "./home/Welcome";
import DownloadList from "./home/DownloadList";
import Footer from "./Footer";

const Home = () => {
  const { userData, setUserData } = useUser();

  useEffect(() => {
    const storedData = getLocalStorage(LocalStorageKey.SIGNUP_DATA);
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        setUserData(parsedData);
      } catch (error) {
        console.error("Failed to parse stored data:", error);
        setUserData(null);
      }
    }
  }, [setUserData]);

  return (
    <>
      {userData ? (
        <>
          <div className="min-h-screen pt-72 pb-8 ">
            <Welcome />
            <DownloadList />
          </div>
          <Footer />
        </>
      ) : (
        <div
          className="h-screen bg-cover bg-center pt-130 pb-90 relative"
          style={{ backgroundImage: "url(/images/MediDash-OneOnc1.png)" }}
        >
          <div className="container mx-auto px-4">
            <Feedback />
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
