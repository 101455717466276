import React from "react";
import WhiteLogo from "../icon/WhiteLogo";
import DownloadIcon from "../icon/DownloadIcon";

interface cardProps {
  title: string;
  description: string;
  selected: boolean;
  onSelect: () => void;
  handleDownload: () => void;
  handleDownloadAbbr: () => void;
}

const Cards: React.FC<cardProps> = ({
  title,
  description,
  selected,
  onSelect,
  handleDownload,
  handleDownloadAbbr,
}) => {
  return (
    <div
      className={`rounded-tl-2xl rounded-br-2xl overflow-hidden shadow-ssm flex flex-col`}
    >
      <div className="relative bg-sherwoodGreen pt-12 pb-12 pl-16 pr-16">
        <WhiteLogo />
        <h4 className="text-white text-xl font-medium pl-52 mt-1">{title}</h4>
        <button onClick={onSelect} className="absolute right-3 top-3">
          {selected ? (
            <img
              src="/images/downloaded-right.svg"
              alt="Downloaded"
              className="w-5 h-5"
            />
          ) : (
            <img
              src="/images/check-box.svg"
              alt="Download"
              className="w-5 h-5"
            />
          )}
        </button>
      </div>
      <div className="bg-white pt-6 pb-10 px-6 flex-1 flex flex-col">
        <h4 className="text-limedSpruce text-xl font-normal font-hahmlet mb-3">
          {title}
        </h4>
        <p className="text-limedSpruce text-lg font-normal leading-6 mb-6">
          {description}
        </p>
        <div className="flex-1 flex flex-col justify-end">
          <button
            onClick={handleDownloadAbbr}
            className={`flex justify-center items-center gap-3 bg-eucalyptus text-white leading-none px-12 py-4 rounded-full text-base font-medium w-full mb-px10`}
          >
            Abbreviated version <DownloadIcon width="19px" height="19px" />
          </button>
          <button
            onClick={handleDownload}
            className={`flex justify-center items-center gap-3 bg-eucalyptus text-white leading-none px-12 py-4 rounded-full text-base font-medium w-full`}
          >
            Expanded version <DownloadIcon width="19px" height="19px" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cards;
