import React, { useState } from "react";
import { setLocalStorage } from "../utils/localStorage";
import { LocalStorageKey } from "../utils/constant";
import { useUser } from "../context/UserContext";

interface SignUpProps {
  setIsOpen: (isOpen: boolean) => void;
}

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  agreed: boolean;
}

interface Errors {
  firstName?: string;
  lastName?: string;
  email?: string;
  agreed?: string;
}

const sendDataToHook =  async (data: object) => {
  await fetch('https://bgb.app.n8n.cloud/webhook/9bbbeeff-b900-4324-9c24-29fdee64ee47', {
  method: "POST",
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify(data),
});
}

const SignUp: React.FC<SignUpProps> = ({ setIsOpen }) => {
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    email: "",
    agreed: false,
  });

  const [errors, setErrors] = useState<Errors>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { setUserData } = useUser();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [id]: type === "checkbox" ? checked : value,
    });
  };

  const validate = (): Errors => {
    const newErrors: Errors = {};

    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!formData.agreed) newErrors.agreed = "You must agree to the terms";

    return newErrors;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
      setLocalStorage(LocalStorageKey.SIGNUP_DATA, JSON.stringify(formData));
      setUserData(formData);
      sendDataToHook({
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
      });
      setIsOpen(false);
    }
  };

  return (
    <div className="bg-white p-16 rounded-3xl shadow-md flex w-w527 signUpFormMail flex-col justify-center items-start gap-2">
      <h2 className="text-34md font-normal signUp leading-10 tracking-m1_6 text-darkJungleGreen text-center font-feature-custom">
        Confirm your information
      </h2>
      <form className="signupform" onSubmit={handleSubmit}>
        <div className="flex justify-center gap-5 mt-6 mb-6">
          <div className="relative">
            <input
              type="text"
              id="firstName"
              placeholder=" "
              value={formData.firstName}
              onChange={handleInputChange}
              className={`peer h-12 w-full px-3 py-2 border rounded-md ${
                errors.firstName ? "border-red-500" : "border-silverChalice"
              } text-darkJungleGreen focus:border-eucalyptus outline-none transition duration-200 ease-in-out`}
            />
            <label
              htmlFor="firstName"
              className="absolute left-3 -top-2.5 text-lemonGrass peer-placeholder-shown:top-3 peer-placeholder-shown:text-lemonGrass peer-focus:-top-2.5 peer-focus:text-sm peer-focus:text-silverChalice transition-all duration-200 ease-in-out bg-white px-1"
            >
              First Name
            </label>
            {errors.firstName && (
              <span className="text-red-500">{errors.firstName}</span>
            )}
          </div>

          <div className="relative">
            <input
              type="text"
              id="lastName"
              placeholder=" "
              value={formData.lastName}
              onChange={handleInputChange}
              className={`peer h-12 w-full px-3 py-2 border rounded-md ${
                errors.lastName ? "border-red-500" : "border-silverChalice"
              } text-darkJungleGreen focus:border-eucalyptus outline-none transition duration-200 ease-in-out`}
            />
            <label
              htmlFor="lastName"
              className="absolute left-3 -top-2.5 text-lemonGrass peer-placeholder-shown:top-3 peer-placeholder-shown:text-lemonGrass peer-focus:-top-2.5 peer-focus:text-sm peer-focus:text-silverChalice transition-all duration-200 ease-in-out bg-white px-1"
            >
              Last Name
            </label>
            {errors.lastName && (
              <span className="text-red-500">{errors.lastName}</span>
            )}
          </div>
        </div>

        <div className="relative mt-6 mb-6">
          <input
            type="email"
            id="email"
            placeholder=" "
            value={formData.email}
            onChange={handleInputChange}
            className={`peer h-12 w-full px-3 py-2 border rounded-md ${
              errors.email ? "border-red-500" : "border-silverChalice"
            } text-darkJungleGreen focus:border-eucalyptus outline-none transition duration-200 ease-in-out`}
          />
          <label
            htmlFor="email"
            className="absolute left-3 -top-2.5 text-lemonGrass peer-placeholder-shown:top-3 peer-placeholder-shown:text-lemonGrass peer-focus:-top-2.5 peer-focus:text-sm peer-focus:text-eucalyptus transition-all duration-200 ease-in-out bg-white px-1"
          >
            Email
          </label>
          {errors.email && <span className="text-red-500">{errors.email}</span>}
        </div>

        <div className="mt-6 mb-6">
          <label className="inline-flex items-start">
            <input
              type="checkbox"
              id="agreed"
              checked={formData.agreed}
              onChange={handleInputChange}
              className="form-checkbox h-6 w-6 text-green-600 accent-eucalyptus"
            />
            <span className="ml-2 text-darkJungleGreen">
              I agree to the &nbsp;
              <a className="text-eucalyptus" href="https://www.oneoncology.com/terms-and-conditions" target="_blank">Terms of Use</a> and &nbsp;
              <a className="text-eucalyptus" href="https://www.oneoncology.com/privacy/" target="_blank">Privacy Policy</a>
            </span>
          </label>
          {errors.agreed && (
            <span className="text-red-500">{errors.agreed}</span>
          )}
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full bg-eucalyptus text-white p-3 rounded-full transition duration-200 ${
            isSubmitting
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-eucalyptus"
          }`}
        >
          Continue to Download
        </button>
      </form>
    </div>
  );
};

export default SignUp;
